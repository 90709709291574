import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
//import {FacebookProvider, LoginButton } from 'react-facebook';


import logo from "../../Assets/logo.png"
//import fblogo from "../../Assets/fblogo.png"

import { useNavigate } from 'react-router-dom';
import Toast from '../../components/toast/Toast'


const Login = () => {

    let navigate = useNavigate();


    const [credentials, setCredentials] = useState({ emailId: "", password: "" })
    const [toastArgs, setToastArgs] = useState(null)


    const showToast = (message, type) => {
        setToastArgs({
            message,
            type
        })
        setTimeout(() => {
            setToastArgs(null)
        }, 3000);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch("https://api.couriero.in/api/auth/login", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ emailId: credentials.emailId, password: credentials.password })
        })
        const json = await response.json()
        console.log(json)
        if (json.AuthToken) {
            // Redirect
            localStorage.setItem('token', json.AuthToken)
            navigate("/")
        } else {
            showToast("Invalid Username or Password", 'error')
        }
    }

    const onChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const handleSignIn = async (credentialResponse) => {
        const response = await fetch('https://api.couriero.in/google/signin/callback', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                credentialResponse: credentialResponse
            })

        })

        const result = await response.json()

        if (result.status === 'success') {
            localStorage.setItem('token', result.AuthToken)
            navigate('/')
        } else if (result.status === 'error') {
            showToast(`${result.message}`, 'error')

        }
    }

    

    return (
        <React.Fragment>
            <div className='w-full h-full fixed items-center z-0 bg-gray-50 flex flex-col align-middle overflow-y-scroll'>
                <img src={logo} className=' w-60 h-10 my-10 ' alt='logo' />
                <div className='bg-white lg:w-[30rem] lg:h-[30rem] p-4 relative rounded-2xl shadow-2xl align-middle'>
                    {toastArgs !== null && <Toast toastArgs={toastArgs} />}
                    <div className=''>
                        <h3 className='text-center my-4 font-bold text-gray-500 text-lg' >Login To Couriero with</h3>
                        <div className='flex flex-row justify-evenly mt-8 mb-2'>
                            <div>
                                <GoogleOAuthProvider clientId="86166203287-69tfbeagj3944a4b0crs255smug2a7v2.apps.googleusercontent.com">
                                    <GoogleLogin
                                        onSuccess={credentialResponse => {
                                            handleSignIn(credentialResponse)
                                        }}
                                        shape='pill'
                                        size='medium'
                                        text='signin_with'
                                    />
                                </GoogleOAuthProvider>
                            </div>
                            {/* <div className='rounded-2xl border flex-row flex justify-center align-middle px-4 py-1 cursor-pointer'>
                                <img src={fblogo} className="w-5 h-5 mr-2" alt='google' />
                                <p className='align-middle text-sm '>Facebook</p>
                            </div> */}
                            {/* <div>
                                <FacebookProvider appId="3609634182581774">
                                    <LoginButton
                                        scope="email"
                                        onSuccess={handleFbSuccess}
                                        fields="name,email,picture"
                                    >
                                        Login via Facebook
                                    </LoginButton>
                                </FacebookProvider>
                              
                            </div> */}
                        </div>
                        <div className="w-full flex items-center justify-between py-2">
                            <hr className="w-full bg-gray-600" />
                            <p className="text-base font-medium leading-4 px-2.5 text-gray-500">OR</p>
                            <hr className="w-full bg-gray-600  " />
                        </div>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='flex flex-col items-center my-2'>
                            <div className='flex flex-col w-full' >
                                <div className='mb-4 mx-6'>
                                    <label htmlFor='email' className="text-sm font-medium leading-none text-gray-800">Email</label>
                                    <input onChange={onChange} value={credentials.emailId} name='emailId' id='emailId' placeholder="Enter email adress" type="email" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-2" />
                                </div>
                                <div className='my-2 mx-6'>
                                    <label htmlFor='password' className="text-sm font-medium leading-none text-gray-800">Password</label>
                                    <input onChange={onChange} value={credentials.password} name='password' id='password' placeholder="Enter Your Password here" type="password" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-2" />
                                </div>
                            </div>
                            <div className="flex flex-col items-center my-8 w-1/2 mx-20">
                                <button type='submit' className="mx-6 text-sm font-semibold leading-none text-white border rounded-2xl hover:bg-blue-600 bg-blue-500  py-2 w-full">
                                    Login
                                </button>
                                <div className='my-4'>
                                    <Link to='/forgot-password'>
                                        <span className='text-blue-600 hover:underline cursor-pointer text-sm'>Forgot Password ?</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div className='flex flex-row text-sm justify-center align-middle'>
                        <span className='mx-2'>Don't have an account?</span>
                        <Link to='/signup'>
                            <span className='text-blue-600 hover:underline cursor-pointer font-semibold'>Sign Up Now</span>
                        </Link>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login
