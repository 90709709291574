import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ColorRing } from 'react-loader-spinner'
import CreateShipmentPage2 from '../createShipment/CreateShipmentPage2'
import { useDispatch } from 'react-redux'
import { getAllOrders } from '../../Store/orders/ordersActions'
import { getAllWalletTransaction } from '../../Store/wallet/walletDataAction'
import Toast from '../../components/toast/Toast'
import { getAllChannelOrders } from '../../Store/channelOrders/channelOrdersActions'

const ChannelOrdersItems = (props) => {
    const { data } = props

    console.log('all channel orders data is', data)

    const [selectCourierBoxVisibility, setSelectCourierBoxVisibility] = useState("hidden")
    const [selectEditBoxVisibility, setSelectEditBoxVisibility] = useState('hidden')
    const [allPickupAddresses, setAllPickupAddresses] = useState([])
    const [selectedPickupAddress, setSelectedPickupAddress] = useState('')
    const [searchedCompanies, setSearchedCompanies] = useState([])
    const [loaderLoading, setLoaderLoading] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('')
    const [toastArgs, setToastArgs] = useState(null)
    const [channelOrderData, setChannelOrderData] = useState({ date: data.date, productName: data.productName, productAmount: data.productAmount, productOrderType: data.productOrderType, consigneeName: data.consigneeName, consigneeAddress: data.consigneeAddress, consigneePinCode: data.consigneePinCode, consigneeEmail: data.consigneeEmail, consigneePhoneNumber: data.consigneePhoneNumber, shipmentLength: data.shipmentLength, shipmentBredth: data.shipmentBredth, shipmentHeight: data.shipmentHeight, shipmentWeight: data.shipmentWeight, shipmentVolWeight: data.shipmentVolWeight, shipmentChargedWeight: data.shipmentChargedWeight, channelName: data.channelName, shippingStatus: data.shippingStatus })


    const dispatch = useDispatch()

    const showToast = (message, type) => {
        setToastArgs({
            message,
            type
        })
        setTimeout(() => {
            setToastArgs(null)
        }, 3000);
    }



    const capitalizeFirstWord = (input) => {
        if (typeof input !== 'undefined') {
            var word = input.trim().replace(/[\s\t]+/g, ' ');
            return word.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
        };
    }

    const editShipmentOnChange = (e) => {
        setChannelOrderData({ ...channelOrderData, [e.target.name]: e.target.value })
    }

    const actionButton = (status) => {
        switch (status) {
            case 'unshipped':
                return (
                    <div className='flex flex-col items-start pl-1'>
                        <Link
                            to='#'
                            className='py-1 text-blue-700 flex flex-row items-center'
                        >
                            <i className="fa-solid fa-print"></i>
                            <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2' onClick={handleCourierBoxVisibility}>Ship Now</button>
                        </Link>
                        <Link
                            to='#'
                            className='py-1 text-blue-700 flex flex-row items-center'
                        >
                            <i className="text-green-500 fa-solid fa-pen"></i>
                            <button className='text-xs ml-2  bg-green-500 text-white rounded-2xl px-2' onClick={handleEditBoxVisibility}>Edit</button>
                        </Link>

                    </div>
                );
            default:
                return (
                    <div className='flex flex-col items-start pl-1'>
                        <Link
                            to='#'
                            className='py-1 text-blue-700 flex flex-row items-center'
                        >
                            <i className="fa-solid fa-print"></i>
                            <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2'>Ship Now</button>
                        </Link>

                    </div>
                );
        }
    }
    useEffect(() => {
        fetchAllPickupAddresses()
    }, [])

    const fetchAllPickupAddresses = async () => {
        const response = await fetch('https://api.couriero.in/api/pickup-address/fetch-all-pickup-addresses', {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        })
        const result = await response.json()
        setAllPickupAddresses(result)
    }

    const handleSelectedPickupAddressOnChange = (e) => {
        const result = allPickupAddresses.find(obj => {
            return obj._id === e.target.value
        })
        setSelectedPickupAddress(result)
        setSearchedCompanies([])

    }

    const handleDivOnClick = (e) => {
        e.preventDefault()
        setSelectCourierBoxVisibility("hidden")
        setSelectEditBoxVisibility("hidden")
        setChannelOrderData({ date: data.date, productName: data.productName, productAmount: data.productAmount, productOrderType: data.productOrderType, consigneeName: data.consigneeName, consigneeAddress: data.consigneeAddress, consigneePinCode: data.consigneePinCode, consigneeEmail: data.consigneeEmail, consigneePhoneNumber: data.consigneePhoneNumber, shipmentLength: data.shipmentLength, shipmentBredth: data.shipmentBredth, shipmentHeight: data.shipmentHeight, shipmentWeight: data.shipmentWeight, shipmentVolWeight: data.shipmentVolWeight, shipmentChargedWeight: data.shipmentChargedWeight, channelName: data.channelName, shippingStatus: data.shippingStatus })

    }

    const handleCourierBoxVisibility = (e) => {
        e.preventDefault()
        setSelectCourierBoxVisibility("block")
    }

    const handleEditBoxVisibility = (e) => {
        e.preventDefault()
        setSelectEditBoxVisibility("block")
    }

    const searchCourierPartner = async (e) => {
        e.preventDefault()
        setLoaderLoading(true)

        const response = await fetch('https://api.couriero.in/search-courier-companies/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                pickupPincode: selectedPickupAddress.pinCode,
                deliveryPincode: data.consigneePinCode,
                weight: data.shipmentChargedWeight * 1000,
                orderType: data.productOrderType,
                amount: data.productAmount,
            })
        })
        const result = await response.json()
        console.log(result)

        if (result.status !== 'error') {
            setSearchedCompanies(result)
        } else {
            setErrorMsg('No company is serviceable on the above pincodes')
        }

        setLoaderLoading(false)

    }

    const addCourierId = (e) => {
        data.selectedCourierId = e.target.value
    }

    const handleShipWith = async (e) => {
        e.preventDefault()
        setIsLoading(true)

        const selectedCourierCompany = searchedCompanies.find(obj => {
            return obj.id === data.selectedCourierId
        })

        const response = await fetch('https://api.couriero.in/channel-orders/create-channel-shipment', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                channelOrderId: data._id,
                companyName: selectedCourierCompany.courierName,
                companyId: data.selectedCourierId,
                pickupAddressContactName: selectedPickupAddress.contactName,
                delhiveryWarehouseId: selectedPickupAddress.delhiveryWarehouseId,
                delhivery5kgWarehouseId: selectedPickupAddress.delhivery5kgWarehouseId ? selectedPickupAddress.delhivery5kgWarehouseId : '',
                pickupAddressMobileNo: selectedPickupAddress.mobileNo,
                pickupAddressName: selectedPickupAddress.name,
                pickupPinCode: selectedPickupAddress.pinCode,
                pickupAddress: `${selectedPickupAddress.addressLine1}, ${selectedPickupAddress.addressLine2}`,
                pickupAddressCity: selectedPickupAddress.city,
                pickupAddressState: selectedPickupAddress.state,
                productName: data.productName,
                productAmount: data.productAmount,
                productOrderType: data.productOrderType,
                consigneeName: data.consigneeName,
                consigneeEmail: data.consigneeEmail,
                consigneeMobNo: data.consigneePhoneNumber,
                consigneeAddress: data.consigneeAddress,
                deliveryPincode: data.consigneePinCode,
                consigneeCity: data.consigneeCity,
                consigneeState: data.consigneeState,
                shipmentLength: data.shipmentLength,
                shipmentBredth: data.shipmentBredth,
                shipmentHeight: data.shipmentHeight,
                shipmentWeight: data.shipmentWeight,
                shipmentVolWeight: data.shipmentVolWeight,
                shipmentChargedWeight: data.shipmentChargedWeight,
                price: selectedCourierCompany.chargeForward,
                priceRTO: selectedCourierCompany.chargeRTO,
                codCharge: selectedCourierCompany.codCharge,
                logo: selectedCourierCompany.logo
            })
        })

        const result = await response.json()

        if (result.status === 'success') {
            dispatch(getAllOrders())
            dispatch(getAllWalletTransaction())
            setSelectCourierBoxVisibility("hidden")
            showToast(`${result.message}`, 'success')
            dispatch(getAllChannelOrders())

        } else if (result.status === 'error') {
            showToast(`${result.message}`, 'error')
        }


        console.log('final data is', data)
    }

    const saveEditInChannel = async (e) => {
        e.preventDefault()

        const response = await fetch('https://api.couriero.in/channel-orders/edit-channel-order', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                id: data._id,
                consigneeName: channelOrderData.consigneeName,
                consigneeAddress: channelOrderData.consigneeAddress,
                consigneePinCode: channelOrderData.consigneePinCode,
                consigneePhoneNumber: channelOrderData.consigneePhoneNumber,
                shipmentLength: channelOrderData.shipmentLength,
                shipmentBredth: channelOrderData.shipmentBredth,
                shipmentHeight: channelOrderData.shipmentHeight,
                shipmentWeight: channelOrderData.shipmentWeight,
                shipmentVolWeight: channelOrderData.shipmentVolWeight,
                shipmentChargedWeight: (channelOrderData.shipmentWeight > channelOrderData.shipmentVolWeight ? channelOrderData.shipmentWeight : channelOrderData.shipmentVolWeight),
                productName: channelOrderData.productName,
                productAmount: channelOrderData.productAmount,
                productOrderType: channelOrderData.productOrderType
            })
        })

        const result = await response.json()

        if (result.status === 'success') {
            setSelectEditBoxVisibility("hidden")
            dispatch(getAllChannelOrders())
            showToast('Details Changed Successfully', "success")


        }

        console.log(result)
    }


    return (
        <React.Fragment>

            {toastArgs !== null && <Toast toastArgs={toastArgs} />}


            <tr className=' text-gray-700'>
                <td className='text-xs px-1 font-semibold border text-center'>{data.date.split("T")[0]}</td>
                <td className=' items-center align-middle border px-1 py-2'>
                    <p className='font-semibold text-sm'><span className='font-medium text-xs'>Name : </span>{capitalizeFirstWord(data.productName)}</p>
                    <p className='font-semibold text-sm'><span className='font-medium text-xs'>Amount : </span>₹ {data.productAmount}</p>
                    <p className='font-semibold text-sm'><span className='font-medium text-xs'>Order Type : </span><span className='rounded-2xl bg-green-200 px-2'>{data.productOrderType}</span></p>
                </td>
                <td className=' px-2 border items-center py-2'>
                    <p className='font-semibold text-base '>{capitalizeFirstWord(data.consigneeName)}</p>
                    <p className='font-semibold text-xs break-words w-40  '>{data.consigneeEmail}</p>
                    <p className='font-semibold text-xs  '>{data.consigneeAddress}</p>
                    <p className='font-semibold text-xs  '>{data.consigneePinCode}</p>
                    <p className='font-semibold text-xs  '>{data.consigneePhoneNumber}</p>




                </td>
                <td className='text-xs font-semibold pl-1 border text-left py-2'>
                    <div>
                        <p className='text-xs font-semibold'><span className='font-medium text-xs'>Dimension : </span><span>{data.shipmentLength} X {data.shipmentBredth} X {data.shipmentHeight} cm</span></p>
                        <p className='text-xs font-semibold'><span className='font-medium text-xs'>Weight : </span>{data.shipmentWeight} Kg</p>
                        <p className='text-xs font-semibold'><span className='font-medium text-xs'>Vol. Weight : </span>{data.shipmentVolWeight} Kg</p>
                        <p className='text-xs font-semibold'><span className='font-medium text-xs'>Charged Weight : </span><span className='rounded-2xl bg-green-200 px-2'>{data.shipmentChargedWeight} Kg</span></p>
                    </div>
                </td>

                <td className='text-xs font-semibold pl-1 border text-center py-2'>
                    <p className='font-semibold text-xs  '>{capitalizeFirstWord(data.channelName)}</p>
                </td>
                <td className='text-xs font-semibold pl-1 border text-center py-2'>
                    <p className='font-semibold text-xs  '>{capitalizeFirstWord(data.shippingStatus)}</p>
                </td>
                <td className='text-sm text-center border py-2 '>
                    {actionButton(data.shippingStatus)}

                    <div className={`${selectCourierBoxVisibility} fixed inset-0 z-[1000] overflow-auto flex justify-center items-center`}>
                        <div className='absolute top-0 bottom-0 left-0 right-0  z-[1000] flex justify-center items-center'>
                            <div className='opacity-60 blur-lg fixed w-full h-full bg-black' onClick={handleDivOnClick}>
                            </div>
                            <div className='bg-white w-10/12 h-[45rem] rounded-2xl shadow-2xl text-gray-500 block absolute overflow-y-auto'>
                                <div className=''>
                                    <div className='text-center p-4 m-1  bg-blue-200 mt-1 rounded-2xl'>
                                        <h3 className='text-lg font-bold'>Select Courier Partner</h3>
                                    </div>
                                    <div className='text-center flex flex-col px-4 my-8'>
                                        <h3 className='my-2 text-lg font-medium'>Please Select the Pickup Address</h3>
                                        <div className='flex flex-col items-center w-full my-4'>
                                            <div className=' w-full' >
                                                <select
                                                    className='w-3/5 text-xs font-normal border-solid border-2 border-gray-300 rounded-2xl py-1 px-2'
                                                    onChange={handleSelectedPickupAddressOnChange}
                                                    defaultValue=''
                                                    required
                                                >
                                                    <option value="" disabled>Select your Pickup Address</option>
                                                    {allPickupAddresses.map((element) => { return <option key={element._id} value={element._id} >{`${element.name} (${element.addressLine1})`}</option> })}
                                                </select>
                                            </div>

                                        </div>
                                        {
                                            loaderLoading && (
                                                <div className='flex items-center justify-center py-20'>
                                                    <ColorRing
                                                        visible={true}
                                                        height="80"
                                                        width="80"
                                                        ariaLabel="blocks-loading"
                                                        wrapperStyle={{}}
                                                        wrapperClass="blocks-wrapper"
                                                        colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                                                    />
                                                </div>
                                            )
                                        }
                                        {
                                            !loaderLoading && searchedCompanies.length === 0 && (
                                                <div>
                                                    {errorMsg}
                                                </div>

                                            )
                                        }


                                        {
                                            !loaderLoading && searchedCompanies.length !== 0 && (
                                                <div className='p-4 mt-3'>
                                                    <form onSubmit={handleShipWith}>

                                                        {searchedCompanies.map((element) => {
                                                            return <CreateShipmentPage2
                                                                courierName={element.courierName}
                                                                key={element.id}
                                                                id={element.id}
                                                                chargeForward={element.chargeForward}
                                                                codCharge={element.codCharge}
                                                                logo={element.logo}
                                                                chargeRTO={element.chargeRTO}
                                                                deliveryDate={element.deliveryDate}
                                                                pickupDate={element.pickupDate}
                                                                minWeight={element.minWeight}
                                                                selectedCourierIdOnChange={addCourierId}
                                                            />
                                                        })}
                                                        <div className='flex flex-row items-center justify-center'>
                                                            <button
                                                                className='bg-red-500 rounded-2xl md:px-3 px-2 py-1 md:mx-2 mx-1 md:text-sm text-xs cursor-pointer text-white font-medium mb-8'
                                                                onClick={handleDivOnClick}
                                                            >
                                                                Cancel
                                                            </button>
                                                            <button
                                                                className='bg-blue-500 rounded-2xl md:px-3 px-2 py-1 md:mx-2 mx-1 md:text-sm text-xs cursor-pointer text-white font-medium mb-8'
                                                                type='submit'
                                                                disabled={isLoading}
                                                            >
                                                                {isLoading ? 'Shipping...' : 'Ship Now'}
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            )
                                        }

                                        {searchedCompanies.length === 0 && (
                                            <div className='flex flex-row items-center justify-center my-10'>
                                                <button className='mx-2 bg-red-500 rounded-2xl px-4 text-white' onClick={handleDivOnClick}>Cancel</button>
                                                <button className='mx-2 bg-blue-500 rounded-2xl px-4 text-white' onClick={searchCourierPartner}>Search Courier Partners</button>
                                            </div>
                                        )
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={`${selectEditBoxVisibility} fixed inset-0 overflow-y-auto  py-12   flex items-center  justify-center z-30`}>
                        <div className="absolute   inset-0 bg-black opacity-50"></div>
                        <div className='opacity-60 blur-lg fixed w-full h-full bg-black' onClick={handleDivOnClick}>
                        </div>
                        <div className="bg-white text-slate-800 font-medium  p-6 pt-2 rounded-lg shadow-lg z-10">
                            <form onSubmit={saveEditInChannel}>

                                <div>
                                    {/* <h2 className="text-xl font-semibold text-blue-900  mb-4">Edit Your Data</h2> */}
                                    <div className='flex  flex-col gap-2 '>
                                        <div className='flex  border border-slate-400 py-5 px-4  w-full flex-col justify-center items-center gap-3'>
                                            <h3 className='text-lg lg:mt-0 mt-16 mb-5 font-semibold text-gray-500 underline md:pl-4 '>Consignee Details</h3>
                                            <div className='flex flex-row gap-2 w-full'>
                                                <div className='flex w-full  flex-col lg:flex-row gap-2'>
                                                    <h3 className='flex w-1/6'>Name:</h3>
                                                    <input
                                                        type="text"
                                                        id="consigneeName"
                                                        className='rounded-full pl-2 focus:outline-none py-[1.4px] text-xs border-slate-300 border w-5/6 flex'
                                                        value={channelOrderData.consigneeName}
                                                        name='consigneeName'
                                                        onChange={editShipmentOnChange}
                                                        required
                                                    />
                                                </div>
                                                <div className='flex w-full flex-col lg:flex-row gap-2'>
                                                    <h3 className='flex w-1/6'>Address:</h3>
                                                    <input
                                                        type="text"
                                                        id="consigneeAddress"
                                                        className='rounded-full pl-2 focus:outline-none py-[1.4px] text-xs border-slate-300 border w-5/6 flex'
                                                        value={channelOrderData.consigneeAddress}
                                                        name='consigneeAddress'
                                                        onChange={editShipmentOnChange}
                                                        required
                                                    />

                                                </div>
                                            </div>
                                            <div className='flex flex-row w-full gap-2'>
                                                <div className='flex w-full flex-col lg:flex-row gap-2'>
                                                    <h3 className='flex w-1/6'>Pincode:</h3>
                                                    <input
                                                        type="text"
                                                        id='consigneePinCode'
                                                        maxLength={6}
                                                        pattern='[0-9]{6}'
                                                        className='rounded-full pl-2 focus:outline-none py-[1.4px] text-xs border-slate-300 border w-5/6 flex'
                                                        value={channelOrderData.consigneePinCode}
                                                        name='consigneePinCode'
                                                        onChange={editShipmentOnChange}
                                                        required
                                                    />
                                                </div>
                                                <div className='flex w-full flex-col lg:flex-row gap-2'>
                                                    <h3 className='flex w-1/6'>Number:</h3>
                                                    <input
                                                        type='text'
                                                        className='rounded-full pl-2 focus:outline-none py-[1.4px] text-xs border-slate-300 border w-5/6 flex'
                                                        value={channelOrderData.consigneePhoneNumber}
                                                        minLength={10}
                                                        maxLength={10}
                                                        pattern='[6-9]{1}[0-9]{9}'
                                                        name='consigneePhoneNumber'
                                                        onChange={editShipmentOnChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex border border-slate-400 py-5 px-4  w-full flex-col justify-center items-center gap-3'>
                                            <h3 className='text-lg mb-2 font-semibold text-gray-500 underline md:pl-4'>Product Details</h3>
                                            <div className='flex flex-row gap-2 w-full'>

                                                <div className='flex w-full flex-col lg:flex-row gap-2'>
                                                    <h3 className='flex w-1/6'>Name:</h3>
                                                    <input
                                                        type="text"
                                                        className='rounded-full pl-2 focus:outline-none py-[1.4px] text-xs border-slate-300 border w-5/6 flex'
                                                        value={channelOrderData.productName}
                                                        id="productName"
                                                        name='productName'
                                                        onChange={editShipmentOnChange}
                                                        required
                                                    />
                                                </div>
                                                <div className='flex flex-col lg:flex-row w-full   gap-2'>
                                                    <h3 className='flex w-1/6'>Amount:</h3>
                                                    <input
                                                        type="number"
                                                        className='rounded-full pl-2 focus:outline-none py-[1.4px] text-xs border-slate-300 border w-5/6 flex'
                                                        value={channelOrderData.productAmount}
                                                        name='productAmount'
                                                        id="productAmount"
                                                        onChange={editShipmentOnChange}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className='flex mt-4 flex-row justify-center items-center w-full gap-5'>

                                                <div className='flex'>
                                                    <label className='font-medium text-base'>Order Type:</label>
                                                </div>
                                                <div className='flex flex-row items-center' >
                                                    <div className='flex flex-row items-center'>
                                                        <input
                                                            type="radio"
                                                            id="cod"
                                                            className="rounded-full border border-solid border-gray-500 cursor-pointer"
                                                            name='productOrderType'
                                                            value='cod'
                                                            required
                                                            checked={channelOrderData.productOrderType === 'cod' ? "checked" : ""}
                                                            onChange={editShipmentOnChange}
                                                        />
                                                        <label
                                                            className="cursor-pointer text-black text-sm ml-2 mr-4"
                                                            htmlFor="cod"
                                                        >
                                                            COD
                                                        </label>
                                                    </div>
                                                    <div className='flex  flex-row items-center '>
                                                        <input
                                                            type="radio"
                                                            id="prepaid"
                                                            className=" rounded-full border border-solid border-gray-500 cursor-pointer"
                                                            name='productOrderType'
                                                            value='prepaid'
                                                            checked={channelOrderData.productOrderType === 'prepaid' ? "checked" : ""}
                                                            required
                                                            onChange={editShipmentOnChange}
                                                        />
                                                        <label
                                                            className="  cursor-pointer text-black text-sm ml-2  mr-4"
                                                            htmlFor="prepaid"
                                                        >
                                                            Prepaid
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex border border-slate-400 py-5 px-4  w-full flex-col justify-center items-center gap-3 '>
                                            <div>
                                                <h4 className='text-lg mb-2 font-semibold text-gray-500 underline md:pl-4 py-2'>Weight & dimension Details</h4>
                                            </div>
                                            <div className='flex md:flex-row flex-col items-center'>
                                                <div className='flex flex-col md:w-1/3 md:ml-4 w-full my-2'>
                                                    <div className='mb-2'>
                                                        <label className='font-medium'>Shipment Weight (in Kg)</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            className="w-11/12 px-3 py-1 text-xs font-normal border border-solid border-gray-300 rounded-full"
                                                            id="weight"
                                                            placeholder="Enter Weight of the Shipment in Kg"
                                                            name='shipmentWeight'
                                                            value={channelOrderData.shipmentWeight}
                                                            onChange={editShipmentOnChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className='flex flex-col md:w-1/3 md:ml-4 w-full my-2'>
                                                    <div className='mb-2'>
                                                        <label className='font-medium'>Dimensions (in cm)</label>
                                                    </div>
                                                    <div className='flex flex-row items-center justify-between w-full'>
                                                        <input
                                                            type="number"
                                                            className="w-3/12 text-xs font-normal border border-solid border-gray-300 text-center rounded-2xl py-1"
                                                            id="length"
                                                            placeholder="Length"
                                                            name='shipmentLength'
                                                            value={channelOrderData.shipmentLength}
                                                            onChange={editShipmentOnChange}
                                                            required
                                                        />
                                                        <span className='mx-1'>X</span>
                                                        <input
                                                            type="number"
                                                            className="w-3/12 text-xs font-normal border border-solid border-gray-300 text-center rounded-2xl py-1"
                                                            id="Bredth"
                                                            placeholder="Bredth"
                                                            name='shipmentBredth'
                                                            value={channelOrderData.shipmentBredth}
                                                            onChange={editShipmentOnChange}
                                                            required
                                                        />
                                                        <span className='mx-1'>X</span>
                                                        <input
                                                            type="number"
                                                            className="w-3/12 text-xs font-normal border border-solid border-gray-300 text-center rounded-2xl py-1"
                                                            id="Height"
                                                            placeholder="Height"
                                                            name='shipmentHeight'
                                                            value={channelOrderData.shipmentHeight}
                                                            onChange={editShipmentOnChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div className='flex flex-col md:w-1/3 md:ml-10 w-full my-2'>
                                                    <div className='mb-2'>
                                                        <label className='font-medium'>Volumetric Weight (in Kg)</label>
                                                    </div>
                                                    <div>
                                                        <input
                                                            type="text"
                                                            className="w-10/12 px-3 py-1 text-xs font-normal border border-solid border-gray-300 rounded-2xl"
                                                            id="shipmentVolWeight"
                                                            disabled
                                                            value={(channelOrderData.shipmentLength * channelOrderData.shipmentBredth * channelOrderData.shipmentHeight) / 4000}
                                                            name='shipmentVolWeight'
                                                            onChange={editShipmentOnChange}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex gap-3 mt-4 justify-center items-center">
                                        <button
                                            className="bg-gray-300 text-gray-700 font-medium px-6 shadow-md py-2 rounded-full "
                                            onClick={handleDivOnClick}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className="bg-blue-500  text-white font-medium px-8 shadow-md py-2 rounded-full "
                                            type='submit'
                                        >
                                            Save
                                        </button>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </div>

                </td>

            </tr>
        </React.Fragment >
    )
}

export default ChannelOrdersItems
