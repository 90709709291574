import React from 'react'
import { useState, useEffect } from 'react'
import ProfileMenu from "../common/ProfileMenu"
import { useDispatch, useSelector } from 'react-redux'
import { getAllWalletTransaction } from '../../Store/wallet/walletDataAction'
import { getAllOrders } from '../../Store/orders/ordersActions'
import { getUserDetails } from '../../Store/userDetails/userDetailsActions'
import { Link, useNavigate } from 'react-router-dom'
import logo from "../../Assets/logo.png"
import smallLogo from "../../Assets/logo-small.jpeg"
import Toast from '../../components/toast/Toast'
// import { load } from "@cashfreepayments/cashfree-js";



const Navbar = props => {
  const userDetails = useSelector((state) => state.userDetails)
  const allOrdersData = useSelector((state) => state.ordersData.allOrders)
  const walletData = useSelector((state) => state.walletData);

  const navigate = useNavigate()

  const dispatch = useDispatch()
  const [amount, setAmount] = useState(500);
  const [walletAmount, setWalletAmount] = useState([])
  const [searchAwb, setSearchAwb] = useState('')
  const [recharegeBoxVisibility, setRecharegeBoxVisibility] = useState('hidden')
  const [toastArgs, setToastArgs] = useState(null)


  const showToast = (message, type) => {
    setToastArgs({
      message,
      type
    })
    setTimeout(() => {
      setToastArgs(null)
    }, 3000);
  }

  const handleRechargeOnClick = (e) => {
    e.preventDefault()
    setRecharegeBoxVisibility('block')
  }

  const handleDivOnClick = (e) => {
    e.preventDefault()
    setRecharegeBoxVisibility("hidden")
    setAmount(500)
  }


  useEffect(() => {
    fetchWalletTransactions()
    dispatch(getAllOrders())
    dispatch(getUserDetails())
    // eslint-disable-next-line
  }, [])

  const fetchWalletTransactions = () => {
    dispatch(getAllWalletTransaction())
  }

  useEffect(() => {
    setWalletAmount(walletData[0])
  }, [walletData])


  const handleWalletOnChange = (e) => {
    setAmount(e.target.value)
  }

  const redirectRazorpayPaymentGateway = (e) => {
    e.preventDefault()
    const script = document.createElement('script')
    script.src = "https://checkout.razorpay.com/v1/checkout.js"
    document.body.appendChild(script)
    script.onload = loadRazorpayCheckout()
  }

  const loadRazorpayCheckout = async () => {

    const response = await fetch('https://api.couriero.in/razorpay/get-orderId', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ amount: amount })
    })

    const result = await response.json()

    const options = {
      "key": process.env.RAZORPAY_API_KEY,
      "amount": result.amount,
      "currency": result.currency,
      "name": "Couriero",
      "description": "Add to Couriero Wallet",
      "image": { logo },
      "order_id": result.id,
      handler: async function (response) {
        const paymentDetails = await fetch('https://api.couriero.in/razorpay/verify-payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('token')
          },
          body: JSON.stringify({
            amount: amount,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            transactionId: result.receipt
          })
        })

        console.log('the recieved response from razorpay is', response)

        const paymentDetailsResult = await paymentDetails.json()

        if (paymentDetailsResult.status === 'success') {
          fetchWalletTransactions()
          setRecharegeBoxVisibility("hidden")
          setAmount(500)
          showToast("Amount added successfully in Wallet", 'success')
        } else {
          showToast("Payment Unsuccessful", 'error')
        }
        console.log(paymentDetailsResult)


      },
      "prefill": {
        "name": userDetails.name,
        "email": userDetails.emailId,
        "contact": userDetails.mobNo
      },
      "theme": {
        "color": "#3399cc"
      }
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  // const redirectCashfreePaymentGateway = (e) => {
  //   e.preventDefault()
  //   loadCashfreeCheckout()
  // }

  // const loadCashfreeCheckout = async () => {
  //   const orderIdResponse = await fetch('https://api.couriero.in/cashfree/get-orderId', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({
  //       amount: amount,
  //       name: userDetails.name,
  //       email: userDetails.emailId,
  //       contact: `${userDetails.mobNo}`,
  //       userId: userDetails._id
  //     })
  //   })

  //   const orderIdResult = await orderIdResponse.json()
  //   console.log('cashfree result is', orderIdResult)

  //   const checkPaymentStatus = async () => {
  //     const paymentStatusResponse = await fetch(`https://api.couriero.in/cashfree/check-payment`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'auth-token': localStorage.getItem('token')
  //       },
  //       body: JSON.stringify({
  //         orderId: orderIdResult.order_id
  //       })
  //     })

  //     const paymentStatusResponseResult = await paymentStatusResponse.json()
  //     console.log('the result of pay check', paymentStatusResponseResult)

  //     if (paymentStatusResponseResult.status === 'success') {
  //       fetchWalletTransactions()
  //       setRecharegeBoxVisibility("hidden")
  //       setAmount(500)
  //       showToast("Amount added successfully in Wallet", 'success')
  //     } else {
  //       showToast("Payment Unsuccessful", 'error')
  //     }
  //   }

  //   if (orderIdResult.payment_session_id) {

  //     const cashfree = await load({
  //       mode: "production" //or production
  //     });


  //     let checkoutOptions = {
  //       paymentSessionId: orderIdResult.payment_session_id,
  //       redirectTarget: "_modal",
  //     };


  //     cashfree.checkout(checkoutOptions).then((result) => {
  //       if (result.error) {
  //         // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
  //         console.log("User has closed the popup or there is some payment error, Check for Payment Status");
  //         console.log(result.error);
  //       }
  //       if (result.redirect) {
  //         // This will be true when the payment redirection page couldnt be opened in the same window
  //         // This is an exceptional case only when the page is opened inside an inAppBrowser
  //         // In this case the customer will be redirected to return url once payment is completed
  //         console.log("Payment will be redirected");
  //       }
  //       if (result.paymentDetails) {
  //         // This will be called whenever the payment is completed irrespective of transaction status
  //         console.log("Payment has been completed, Check for Payment Status");
  //         console.log(result.paymentDetails);
  //         console.log('order id result inside checkout', orderIdResult)
  //         checkPaymentStatus()
  //       }
  //     });
  //   }


  // }



  const searchAwbQuery = (e) => {
    console.log('enter is pressed')
    console.log('search query is', searchAwb)
    navigate(`/orders/details/${searchAwb}`)
    setSearchAwb('')

  }

  const getDateTime = (date) => {
    const dateFormat = new Date(date)
    const day = dateFormat.getDate()
    const month = dateFormat.getMonth() + 1
    const year = dateFormat.getFullYear()
    const time = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
    return `${day}/${month}/${year}, ${time} `
  }


  return (
    <React.Fragment>
      <div className='flex flex-row md:justify-center justify-evenly items-center w-full  '>
        <div className='flex items-center justify-start md:justify-center md:pl-3 md:w-2/12 w-1/12 mx-2 '>
          <div className='md:block hidden'>
            <img src={logo} alt="logo" className='w-36 overflow-hidden' />
          </div>
          <div className='md:hidden'>
            <img src={smallLogo} alt="logo" className='w-8 h-8' />
          </div>
        </div>
        {toastArgs !== null && <Toast toastArgs={toastArgs} />}

        <div className='flex flex-row justify-evenly md:justify-center items-center w-11/12 md:10/12 md:mx-6 mx-2'>

          <div className='rounded-3xl flex items-center md:w-3/12 w-3/12 shadow-2xl mx-2 '>
            <input
              type="text"
              className=" h-7 md:pl-5 pl-1 rounded-3xl outline-none focus:outline-none bg-transparent w-full shadow text-xs font-medium text-gray-500	"
              placeholder="Enter AWB/OrderID/Mob No/Name"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  searchAwbQuery()
                }
              }}
              onChange={(e) => setSearchAwb(e.target.value)}
              value={searchAwb}
            />
          </div>
          <div className='flex flex-col absolute top-14 md:top-12 w-4/5 md:w-2/5 md:left-72 text-slate-600'>
            {allOrdersData.filter((item) => { return searchAwb && (item.consigneeName.toLowerCase().includes(searchAwb.toLowerCase()) || item.consigneeMobNo.toString().includes(searchAwb) || item.orderId.toLowerCase().includes(searchAwb.toLowerCase()) || item.awbNo.toString().includes(searchAwb)) }).map((item) => (

              <div className='my-1 w-full flex flex-col shadow-2xl border-2 bg-gray-100 border-blue-400  rounded-2xl cursor-pointer'
                key={item.orderId}
                onClick={() => {
                  navigate(`/orders/details/${item.awbNo}`)
                  setSearchAwb('')
                }}
              >
                <div className='px-4 py-2 flex flex-row justify-between'>
                  <div className=' flex flex-col mx-2'>
                    <h3 className='text-xs font-semibold'>Order ID</h3>
                    <h2 className='text-sm '>{item.orderId}</h2>
                  </div>

                  <div className='flex flex-col mx-2'>
                    <h3 className='text-xs font-semibold'>Awb No</h3>
                    <h2 className='text-sm '>{item.awbNo}</h2>
                  </div>

                  <div className='md:flex flex-col hidden mx-2 items-end'>
                    <h3 className='text-xs font-semibold'>Date</h3>
                    <h2 className='text-sm '>{getDateTime(item.orderDate)}</h2>
                  </div>
                </div>
                <div className='px-4 py-2 flex flex-row justify-between items-center'>
                  <div className='flex flex-col items-start mx-2'>
                    <h3 className='text-xs font-semibold'>Consignee Name</h3>
                    <h2 className='text-sm '>{item.consigneeName}</h2>
                  </div>
                  <div className='flex flex-col mx-2'>
                    <h3 className='text-xs font-semibold'>Phone Number</h3>
                    <h2 className='text-sm '>{`+91-${item.consigneeMobNo}`}</h2>
                  </div>

                  <div className='flex flex-col mx-2 items-end'>
                    <h3 className='text-xs font-semibold'>Shipment Status</h3>
                    <h2 className='text-sm bg-green-300 rounded-2xl px-2 shadow-2xl border border-blue-50  '>{item.shipmentStatus}</h2>
                  </div>
                </div>
              </div>
            )).slice(0, 2)}
          </div>

          <div className='flex flex-row items-center md:justify-end justify-evenly md:w-9/12 w-9/12'>
            <div className='md:mr-2 mr-1 md:w-2/12'>
              <Link
                to='/wallet'
              >
                <i className="fa-solid fa-wallet text-gray-600 md:text-2xl text-sm font-semibold md:mx-3 mx-1"></i>
                <span className='md:text-base hover:underline hover:text-blue-500 cursor-pointer text-xs font-medium md:font-semibold text-gray-600 items-center'>₹ {walletAmount ? walletAmount.closingBalance : 0}</span>
              </Link>
            </div>
            <div className='md:mr-2 mr-1 md:w-2/12'>
              <button
                className="text-xs md:text-sm md:px-4 px-2 py-1 bg-blue-500 rounded-3xl shadow font-medium text-white text-center"
                onClick={handleRechargeOnClick}

              >
                Recharge
              </button>
            </div>
            <div className='md:w-3/12'>
              <ProfileMenu />
            </div>
          </div>
        </div>

      </div>

      <div className={`${recharegeBoxVisibility} fixed inset-0  z-[1000] flex justify-center items-center`}>
        <form onSubmit={redirectRazorpayPaymentGateway} className='absolute inset-0 z-[1000] flex justify-center items-center'>
          <div className='opacity-60 blur-lg fixed w-full h-full bg-black' onClick={handleDivOnClick}>
          </div>
          <div className='bg-white md:w-3/12 w-10/12 pb-4  rounded-2xl shadow-2xl text-gray-500 block absolute'>
            <div className=''>
              <div className='text-center p-4 m-1  bg-blue-200 mt-1 rounded-2xl'>
                <h3 className='md:text-lg text-sm font-bold'>Wallet Recharge</h3>
              </div>
            </div>
            <div className='flex flex-col'>
              <label className='text-center my-2 font-medium md:text-base text-xs'>Enter The Amount Below</label>
              <input
                className='pl-10  border border-gray-400 mx-20 rounded-2xl h-8 md:text-lg text-base font-medium'
                type='number'
                min={300}
                placeholder="Enter Amount"
                value={amount}
                onChange={handleWalletOnChange}
              />
              <h5 className='text-center font-thin text-xs'>Min Value: ₹ 300 & Max Value: ₹ 10,00,000</h5>
              <span className='absolute md:top-[110px] top-[94px] left-[104px] font-medium text-xl'>₹</span>
              <h3 className='text-center mt-6 md:text-base text-xs '>Or You Can Select From The Amounts Below</h3>
              <div className='flex flex-row justify-around mx-4 my-2'>
                <button className='rounded-2xl border border-gray-400 px-2 md:h-6 h-5 text-xs  bg-orange-200 font-medium' onClick={() => setAmount(500)}>₹ 500</button>
                <button className='rounded-2xl border border-gray-400 px-2 md:h-6 h-5 text-xs  bg-orange-200 font-medium' onClick={() => setAmount(1000)}>₹ 1000</button>
                <button className='rounded-2xl border border-gray-400 px-2 md:h-6 h-5 text-xs  bg-orange-200 font-medium' onClick={() => setAmount(2000)}>₹ 2000</button>
                <button className='rounded-2xl border border-gray-400 px-2 md:h-6 h-5 text-xs  bg-orange-200 font-medium' onClick={() => setAmount(5000)}>₹ 5000</button>
                <button className='rounded-2xl border border-gray-400 px-2 md:h-6 h-5 text-xs  bg-orange-200 font-medium' onClick={() => setAmount(10000)}>₹ 10000</button>
              </div>
              <div className='text-center mt-10'>
                <button className='text-center border bg-blue-500 rounded-2xl text-white px-3 font-medium' type='submit'>Continue To Payment</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  )

}



export default Navbar