import React, { useState } from 'react'
import Breadcrumb from '../../../components/common/Breadcrumb'
import shopifyLogo from '../../../Assets/Shopifylogo.png'
import Toast from '../../../components/toast/Toast'
import { useNavigate } from 'react-router-dom'





const ShopifyChannel = () => {
    const navigate = useNavigate()


    const [shopifyStoreFields, setShopifyStoreFields] = useState({ channelName: 'Shopify', brandName: '', storeUrl: '', apiAccessToken: '', apiKey: '', apiSecret: '' })
    const [toastArgs, setToastArgs] = useState(null)

    const showToast = (message, type) => {
        setToastArgs({
            message,
            type
        })
        setTimeout(() => {
            setToastArgs(null)
        }, 3000);
    }


    const inputOnChange = (e) => {
        setShopifyStoreFields({ ...shopifyStoreFields, [e.target.name]: e.target.value })
    }

    const shopifyOnSubmit = (e) => {
        e.preventDefault()
        handleShopifyIntegration()
    }

    const handleShopifyIntegration = async () => {
        const shopifyDetails = await fetch('https://api.couriero.in/shopify/add', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                channelName: shopifyStoreFields.channelName,
                brandName: shopifyStoreFields.brandName, 
                storeUrl: shopifyStoreFields.storeUrl, 
                apiAccessToken: shopifyStoreFields.apiAccessToken, 
                apiKey: shopifyStoreFields.apiKey, 
                apiSecret: shopifyStoreFields.apiSecret
            })
        })

        const result = await shopifyDetails.json()
        console.log(result)

        if (result.status === 'success') {
            showToast(`${result.message}`, 'success')

            setTimeout(() => {
                navigate('/channels')
            }, 1000);
        } else {
            showToast(`${result.message}`, 'error')
        }
    }

    return (
        <React.Fragment>
            <div className='bg-white mb-12 w-full flex flex-col text-gray-600 overflow-y-auto'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Shopify" title="Add Channel" />
                </div>
                {toastArgs !== null && <Toast toastArgs={toastArgs} />}

                <div className='flex flex-row'>
                    <div className='w-1/3 border-2 rounded-2xl m-2 flex flex-col'>
                        <div className=' border-b-2 bg-blue-50 rounded-2xl '>
                            <div className='mx-2 px-2 py-4 text-center '>
                                <h1 className='font-bold text-xs'>Steps To Connect Shopify Store Using Custom App</h1>
                            </div>
                        </div>
                        <div className='my-4 py-2 mx-2'>
                            <ol className="border-l">
                                <li>
                                    <div className="flex-start flex items-center">
                                        <div className="-ml-[5px] -mt-2 mr-3 h-[9px] w-[9px] rounded-full bg-gray-400">
                                        </div>
                                        <p className="text-xs -mt-2">
                                            Login to shopify Admin Panel and Go to your Store
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Open Settings and Go To Apps and Sales Channel
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Now Click on Develop Apps and then Click on Create App
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Give Couriero as the App Name
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Now Click on Configure Admin API Scopes
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-baseline my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Give <span className='font-bold'>Read & Write access</span> to scopes mentioned below
                                        </p>
                                    </div>
                                    <div className='mx-8'>
                                        <p className='text-xs'>Search the scopes and give access:</p>
                                        <div className='flex flex-col'>
                                            <p className='text-xs underline my-2'>1. Product:</p>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Products</p>
                                            </div>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Product Listings</p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-xs underline my-2'>2. Fulfillment:</p>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Assigned fulfillment orders</p>
                                            </div>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Fulfillment services</p>
                                            </div>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Merchant-managed fulfillment orders</p>
                                            </div>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Third-party fulfillment orders</p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-xs underline my-2'>3. Orders:</p>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Draft orders</p>
                                            </div>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Order editing</p>
                                            </div>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Orders</p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-xs underline my-2'>4. Inventory:</p>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Inventory (Only Read Access)</p>
                                            </div>
                                        </div>
                                        <div className='flex flex-col'>
                                            <p className='text-xs underline my-2'>5. Customers:</p>
                                            <div className='flex flex-row items-center'>
                                                <div
                                                    className="h-[5px] w-[5px] rounded-full bg-gray-400 mx-2">
                                                </div>
                                                <p className='text-xs'>Customers</p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Now Click Save and Go to API Credential Tab
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Click on Install App and then click on confirm Install
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Click on Reveal token Once, copy Admin API Access Token
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Also copy API Key and API Secret and paste in the Couriero Portal
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Now click on save. Also refer to video in case any issue
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <a
                                            href={'https://www.youtube.com/watch?v=PVUpolQHnH0'} target="_blank" rel="noreferrer">
                                            <p className="text-xs hover:underline hover:text-blue-500 cursor-pointer">
                                                Click here to see Video Tutorial
                                            </p>
                                        </a>
                                    </div>
                                </li>
                            </ol>
                        </div>

                    </div>
                    <div className='w-2/3 border-2 rounded-2xl m-2 flex flex-col'>
                        <div className='mx-2 px-4 py-2 border-b-2'>
                            <img src={shopifyLogo} alt='shopifyLogo' className='w-30 h-8' />
                        </div>
                        <div className='mx-6 my-2'>
                            <h1 className=' font-bold'>Custom App Integration</h1>
                            <p className='text-xs font-medium'>Connect your Shopify store to Couriero through your Shopify custom app store url.</p>
                        </div>
                        <form onSubmit={shopifyOnSubmit}>
                            <div className='mx-4 my-4 flex flex-col'>
                                <div className='border-2 rounded-2xl flex flex-col my-2 '>
                                    <div className='px-2 py-2'>
                                        <h3 className='font-bold text-sm'>Basic Details</h3>
                                        <p className='text-xs'>Kindly complete the following information to establish a connection for your Shopify store.</p>
                                    </div>
                                    <div className='my-6 flex flex-col'>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>Channel Name</label>
                                            <input
                                                type='text'
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs '
                                                value={shopifyStoreFields.channelName}
                                                disabled
                                            />
                                        </div>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>Brand Name</label>
                                            <input
                                                type='text'
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs'
                                                onChange={inputOnChange}
                                                name='brandName'
                                                id='brandName'
                                                value={shopifyStoreFields.brandName}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='border-2 rounded-2xl flex flex-col my-2 '>
                                    <div className='px-2 py-2'>
                                        <h3 className='font-bold text-sm'>Shopify Custom App Details</h3>
                                        <p className='text-xs'>Enter The Shopify Custom App Credentials</p>
                                    </div>
                                    <div className='my-6 flex flex-col'>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>Store Url</label>
                                            <input
                                                type='text'
                                                autoComplete="off"
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs '
                                                onChange={inputOnChange}
                                                name='storeUrl'
                                                id='storeUrl'
                                                placeholder='Store Url: https://example.myshopify.com'
                                                value={shopifyStoreFields.storeUrl}
                                                required
                                            />
                                        </div>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>Admin API Access Token</label>
                                            <input
                                                type='password'
                                                autoComplete="new-password"
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs'
                                                onChange={inputOnChange}
                                                name='apiAccessToken'
                                                id='apiAccessToken'
                                                value={shopifyStoreFields.apiAccessToken}
                                                required
                                            />
                                        </div>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>API Key</label>
                                            <input
                                                type='password'
                                                autoComplete="new-password"
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs'
                                                onChange={inputOnChange}
                                                name='apiKey'
                                                id='apiKey'
                                                value={shopifyStoreFields.apiKey}
                                                required
                                            />
                                        </div>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>API Secret Key</label>
                                            <input
                                                type='password'
                                                autoComplete="new-password"
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs'
                                                onChange={inputOnChange}
                                                name='apiSecret'
                                                id='apiSecret'
                                                value={shopifyStoreFields.apiSecret}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='my-10 items-center justify-center flex'>
                                    <button
                                        className='bg-blue-500 text-white rounded-2xl px-4 py-1'
                                        type='submit'
                                    >Save Details</button>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default ShopifyChannel
