import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Breadcrumb from '../../components/common/Breadcrumb'
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { startOfWeek, startOfMonth, endOfMonth, subMonths, subDays, endOfWeek } from 'date-fns';
import { startOfDay, endOfDay } from 'date-fns';


const Wallet = () => {

    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const predefinedRanges = {
        today: [startOfDay(new Date()), endOfDay(new Date())],
        yesterday: [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))],
        thisWeek: [startOfDay(startOfWeek(new Date())), endOfDay(new Date())],
        lastWeek: [startOfDay(startOfWeek(subDays(new Date(), 7))), endOfDay(endOfWeek(subDays(new Date(), 7)))],
        thisMonth: [startOfDay(startOfMonth(new Date())), endOfDay(new Date())],
        lastMonth: [startOfDay(startOfMonth(subMonths(new Date(), 1))),endOfDay( endOfMonth(subMonths(new Date(), 1)))],
        last90Days: [startOfDay(subDays(new Date(), 90)), endOfDay(new Date())],
        
    };

    const handleRangeClick = (range) => {
        const [start, end] = predefinedRanges[range];
        setSelectedRange(range);
        setStartDate(start);
        setEndDate(end);
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        console.log('cvbnm',dates)
        setSelectedRange('custom');
        setStartDate(start);
        setEndDate(end);
    };

    const handleDivOnClick = (e) => {
        e.preventDefault();
        setIsDatePickerVisible(false)
    }

    const walletTransactionData = useSelector((state) => state.walletData)

    const [walletData, setWalletData] = useState([])
    const [searchData, setSearchData] = useState('')
    const [selectedFilter, setSelectedFilter] = useState('All');

    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {

        let filteredData = walletTransactionData;

        if (searchData.length !== 0) {
            filteredData = filteredData.filter((item) =>
                item.transactionId.toLowerCase().includes(searchData.toLowerCase()) ||
                item.reference.orderId.toLowerCase().includes(searchData.toLowerCase()) ||
                (item.reference.awbNo && item.reference.awbNo.toString().includes(searchData))
            );
        }
    

        if (selectedFilter !== 'All') {
            filteredData = filteredData.filter((data) => data.type.toLowerCase() === selectedFilter.toLowerCase());
        }

        if (startDate && endDate) {
            filteredData = filteredData.filter((item) => {
                const itemDate = new Date(item.date);
                return itemDate >= startOfDay(new Date(startDate)) && itemDate <= endOfDay(new Date(endDate));
            });
        }

        setWalletData(filteredData);
        setItemOffset(0);
    }, [walletTransactionData, searchData, selectedFilter, startDate, endDate]);



    const itemsPerPage = 20

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = walletData.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(walletData.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % walletData.length;
        setItemOffset(newOffset);
    };

    const handleSearchBox = (e) => {
        setSearchData(e.target.value)
    }

    const handleFilterChange = (event) => {
        const selectedValue = event.target.value;

        switch (selectedValue) {
            case 'shipping':
                setSelectedFilter('shipping');
                break;
            case 'recharge':
                setSelectedFilter('recharge');
                break;
            case 'rto':
                setSelectedFilter('rto');
                break;
            case 'offer':
                setSelectedFilter('offer');
                break;
            case 'credit':
                setSelectedFilter('credit');
                break;
            default:
                setSelectedFilter('All');
        }
    };
   


    const resetFilter = () => {
        setSearchData('')
        setSelectedFilter('All')
        setEndDate(null)
        setStartDate(null)
        setSelectedRange(null)
    }


    return (
        <React.Fragment>
            <div className=' bg-white mb-12 pb-44 w-full flex flex-col text-gray-600 overflow-y-auto'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Wallet History" title="Billing" />
                </div>

                <div className='flex gap-5   lg:flex-row md:flex-row flex-col mt-4 items-center'>
                    <div className='rounded-3xl  flex items-center md:w-3/12 shadow-2xl md:mx-4 w-full  lg:w-1/2'>
                        <input
                            type="text"
                            className=" h-6 md:pl-5 pl-2 rounded-3xl outline-none focus:outline-none bg-transparent w-full shadow text-xs py-2 font-medium text-gray-500	"
                            placeholder="Search AWB/OrderID/TransactionId"
                            onChange={handleSearchBox}
                            value={searchData}
                        />
                    </div>

                    <div className='flex   gap-3 flow-row lg:w-1/2 md:w-1/2 w-full items-center'>
                        <label className='mr-4 pl-1 font-semibold text-sm'>Filters: </label>
                        <select
                            className='text-xs font-normal border-solid border border-slate-500 rounded-2xl px-4'
                            onChange={handleFilterChange}
                            value={selectedFilter}  
                        >
                            <option value="all">All</option>
                            <option value="shipping">Shipping</option>
                            <option value="recharge">Recharge</option>
                            <option value="rto">RTO</option>
                            <option value="offer">Offer</option>
                            <option value="credit">Credit</option>
                        </select>
                        <button
                            className="text-xs flex  items-center font-normal border-solid border-slate-500 border rounded-2xl  px-6"
                            onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
                        >
                            Filter Dates
                            <i class="fa-solid flex pl-2 pr-0 fa-caret-down"></i>
                        </button>
                        <div>
                            <button onClick={resetFilter} className='text-xs shadow-md flex bg-blue-900 text-white  items-center font-normal border-solid border-slate-500 border rounded-2xl  px-4'>Reset Filters</button>
                        </div>
                    </div>
                </div>
                {isDatePickerVisible && (
                    <>
                        <div className='fixed  w-full h-full' onClick={handleDivOnClick}>
                        </div>
                        <div className='flex  lg:flex-row md:flex-row flex-col'>
                            <div className='lg:flex md:flex hidden lg:w-1/2'>
                            </div>
                            <div className="relative flex  lg:w-1/2">
                                <div className="absolute  w-full flex flex-row ">
                                    <div className="w-1/3 bg-gray-100 p-1">
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1 lg:px-5 py-1 ${selectedRange === 'today' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('today')}
                                        >
                                            Today
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'yesterday' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('yesterday')}
                                        >
                                            Yesterday
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'thisWeek' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('thisWeek')}
                                        >
                                            This Week
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'lastWeek' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('lastWeek')}
                                        >
                                            Last Week
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'thisMonth' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('thisMonth')}
                                        >
                                            This Month
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'lastMonth' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('lastMonth')}
                                        >
                                            Last Month
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'last90Days' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('last90Days')}
                                        >
                                            Last 90 Days
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'custom' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('custom')}
                                        >
                                            Custom
                                        </button>
                                    </div>
                                    <div className="flex w-2/3 flex-col ml-3">
                                        <div className="justify-center items-center flex-col">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={handleDateChange}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                inline
                                                className="border flex w-full border-gray-300 rounded-lg"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className='w-full md:my-4 md:px-4 rounded-2xl md:pb-5'>
                    <table className='table-fixed  py-2 rounded-2xl shadow-xl md:w-full'>
                        <thead className=' bg-blue-100 text-gray-700 rounded-2xl'>
                            <tr className=''>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Date</th>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Transaction Id</th>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Type</th>
                                <th className='py-1 text-sm font-bold w-2/12 border-2'>Reference</th>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Credit</th>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Debit</th>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Closing Balance</th>
                                <th className='py-1 text-sm font-bold w-2/12 border-2'>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((element) => {
                                return (
                                    <tr key={element._id}>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/12'>{element.date.split("T")[0]}</td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/12'>{element.transactionId}</td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/12'>{element.type}</td>
                                        <td className='text-xs border font-medium text-left py-2 px-4 w-2/12'>
                                            <Link to={`/orders/details/${element.reference.awbNo}`}>
                                                <p className='cursor-pointer hover:underline'>{`Order Id - ${element.reference.orderId === '-' ? 'SEE DESCRIPTION' : element.reference.orderId}`}</p>
                                                <p className='cursor-pointer hover:underline'>{`Awb No. - ${element.reference.awbNo === null ? 'SEE DESCRIPTION' : element.reference.awbNo}`}</p>
                                            </Link>
                                        </td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/12'>{element.credit}</td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/12'>{element.debit}</td>
                                        <td className='text-xs border font-bold text-center py-2 px-4 w-1/12'>{element.closingBalance}</td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-2/12'>{element.description}</td>
                                    </tr>
                                )
                            })}

                        </tbody>
                    </table>
                </div>

                <div className='py-5 flex items-center flex-col '>
                    {/* <Pagination totalData={walletTransactionData.length} paginate={paginate} itemsPerPage={itemsPerPage}/> */}
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                            <div className='flex flex-row items-center'>
                                <h3 className='mx-2'>Next</h3>
                                <i className="fa-solid fa-arrow-right"></i>

                            </div>
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        previousLabel={
                            <div className='flex flex-row items-center'>
                                <i className="fa-solid fa-arrow-left"></i>
                                <h3 className='mx-2'>Prev</h3>
                            </div>
                        }

                        renderOnZeroPageCount={null}
                        containerClassName='flex flex-row items-center'
                        pageClassName='mx-1'
                        pageLinkClassName='px-3 border-blue-500 border rounded-2xl hover:bg-blue-500 hover:text-white'
                        activeLinkClassName='bg-blue-500 text-white'
                        previousClassName='bg-blue-500 cursor-pointer rounded-2xl text-white px-2'
                        nextClassName='bg-blue-500 cursor-pointer rounded-2xl text-white px-2'
                    />
                </div>

            </div>
        </React.Fragment>
    )
}

export default Wallet