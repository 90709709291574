import React, { useEffect } from 'react'
import Breadcrumb from '../../../components/common/Breadcrumb'
import shopifyLogo from '../../../Assets/Shopifylogo.png'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllChannels } from '../../../Store/channels/channelsActions'



const ChannelIntegration = () => {

    const dispatch = useDispatch()

    const channelsData = useSelector((state) => state.channelsData)

    useEffect(() => {
        dispatch(getAllChannels())
        // eslint-disable-next-line
    }, [])



    return (
        <React.Fragment>
            <div className=' bg-white mb-12 w-full flex flex-col text-gray-600 overflow-y-auto'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="All Channels" title="Settings" />
                </div>
                <div className='flex flex-row w-full justify-end'>
                    <Link
                        to='/channels/list'
                        className='py-1 text-blue-500 items-center mr-10'
                    >
                        <button className='text-xs font-medium ml-2 py-2  bg-blue-500 text-white rounded-2xl px-2'>
                            <span>
                                <i className="fa-solid fa-plus mx-2"></i>
                            </span>
                            Add New Channel</button>
                    </Link>

                </div>

                <div className='w-full md:my-4 md:px-4 rounded-2xl md:pb-5'>
                    <table className='table-fixed py-2 rounded-2xl shadow-2xl md:w-full'>
                        <thead className=' bg-blue-100 text-gray-700 rounded-2xl'>
                            <tr className=''>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Channel Name</th>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Channel Id</th>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Brand Name</th>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Connection Status</th>
                                <th className='py-1 text-sm font-bold w-1/5 border-2'>Action</th>
                            </tr>
                        </thead>

                        <tbody>
                            {channelsData.map((element) => {
                                return (
                                    <tr key={element._id}>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/5'>
                                            <div className='items-center align-middle flex flex-col'>
                                                <img src={shopifyLogo} alt={element.channelName} className='w-20' />
                                            </div>
                                        </td>
                                        <td className='text-sm border font-medium text-center py-2 px-4 w-1/5'>{element.uniqueId}</td>
                                        <td className='text-sm border font-medium text-center py-2 px-4 w-1/5'>{element.brandName}</td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/5'>
                                            <div className='flex flex-row items-center w-full justify-center'>
                                                <i className="fa-solid fa-circle text-green-500"></i>
                                                <div className='text-xs ml-2  bg-green-500 text-white rounded-2xl px-2'>{element.status}</div>
                                            </div>
                                        </td>
                                        <td className='text-xs border font-medium text-center py-2 px-4 w-1/5'>
                                            <Link
                                                to='#'
                                                className='py-1 text-blue-500'
                                            >
                                                <i className="fa-solid fa-pen"></i>
                                                <button className='text-xs ml-2  bg-blue-500 text-white rounded-2xl px-2'>Edit</button>

                                            </Link>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>


        </React.Fragment>
    )
}

export default ChannelIntegration
