import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import Sidebar from './Sidebar'


const Layout = props => {
  return (
    <React.Fragment>
      <div className='flex flex-col  bg-white w-full overflow-x-hidden'>
        <div className='w-full fixed shadow-2xl z-20 bg-white items-center justify-evenly md:justify-between flex h-14 '>
          <Navbar />
        </div>
        <div className='flex flex-row bg-white'>
          <div className='fixed w-14 md:w-2/12 hover:w-6/12 md:hover:w-2/12 left-0 bg-white h-full top-12 z-10 overflow-y-hidden shadow-2xl'>
            <Sidebar />
          </div>
          <div className='overflow-hidden md:ml-60 ml-14 w-full mt-20 md:px-4 px-6'>
            <div>{props.children}</div>
          </div>
        </div>
        <div className='' >
          <Footer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Layout;
